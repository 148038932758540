import React from "react";

export default {
    backend: {
        host: "https://the-politics-of-covid-19.com/search/api",
        index: "kairos"
    },
    header: {
        placeholder: "Type a word or phrase",
        logo: null,
        title: "Kairos Group Research Portal",
        intro: <span>{"Cutting edge and niche content relevant to environmentally-minded investors, with a focus on lithium, climate change, sustainable finance and macro trends."}</span> 
    }
};